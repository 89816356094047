import { isAfter as dateFnsAfter } from 'date-fns';

/**
 * Return true if dateA is after dateB
 *
 * @param dateA
 * @param dateB
 * @returns
 */
export function isAfter(dateA: Date, dateB: Date): boolean {
    if (!dateA || !dateB) {
        return false;
    }

    return dateFnsAfter(dateA, dateB);
}
