import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { IExceptionTelemetry, ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';

const nativeLog: Record<SeverityLevel, (...args: unknown[]) => void> = {};
nativeLog[SeverityLevel.Critical] = console.error;
nativeLog[SeverityLevel.Error] = console.error;
nativeLog[SeverityLevel.Warning] = console.warn;
nativeLog[SeverityLevel.Information] = console.log;
nativeLog[SeverityLevel.Verbose] = console.debug;

function logNative(severityLevel: SeverityLevel, text: string, properties?: Record<string, unknown>): void {
    // Log via native method
    const nativeMethod = nativeLog[severityLevel];
    if (typeof nativeMethod !== 'function') {
        return;
    }

    if (properties !== undefined) {
        nativeMethod(text, properties);
    } else {
        nativeMethod(text);
    }
}

function logAppInsightsTrace(
    appInsights: ReactPlugin,
    severityLevel: SeverityLevel,
    text: string,
    properties?: Record<string, unknown>,
): void {
    // Log via native method
    logNative(severityLevel, text, properties);

    // Do nothing if app insights isn't defined / initialized
    if (!appInsights) {
        return;
    }

    const trace: ITraceTelemetry = {
        severityLevel: severityLevel,
        message: text,
    };

    appInsights.trackTrace(trace, properties);
}

function logExceptionNative(error: Error, properties?: Record<string, unknown>): void {
    // Log via native method
    const nativeMethod = console.error;

    if (properties !== undefined) {
        nativeMethod('Exception: ', error, properties);
    } else {
        nativeMethod('Exception: ', error);
    }
}

function logAppInsightsException(appInsights: ReactPlugin, error: Error, properties?: Record<string, unknown>): void {
    // Log via native method
    logExceptionNative(error, properties);

    // Do nothing if app insights isn't defined / initialized
    if (!appInsights) {
        return;
    }

    const trace: IExceptionTelemetry = {
        severityLevel: SeverityLevel.Error,
        exception: error,
    };
    appInsights.trackException(trace, properties);
}

export function logException(appInsights: ReactPlugin, error: Error, properties?: Record<string, unknown>): void {
    logAppInsightsException(appInsights, error, properties);
}

/**
 * Write to the context's info log, with a fallback on console log
 *
 * @param context
 * @param args
 */
export function logDebug(appInsights: ReactPlugin, text: string, properties?: Record<string, unknown>): void {
    logAppInsightsTrace(appInsights, SeverityLevel.Verbose, text, properties);
}

/**
 * Write to the context's info log, with a fallback on console log
 *
 * @param context
 * @param args
 */
export function logInfo(appInsights: ReactPlugin, text: string, properties?: Record<string, unknown>): void {
    logAppInsightsTrace(appInsights, SeverityLevel.Information, text, properties);
}

/**
 * Write to the context's warning log, with a fallback on console log
 *
 * @param context
 * @param args
 */
export function logWarning(appInsights: ReactPlugin, text: string, properties?: Record<string, unknown>): void {
    logAppInsightsTrace(appInsights, SeverityLevel.Warning, text, properties);
}

/**
 * Write to the context's error log, with a fallback on console log
 *
 * @param context
 * @param args
 */
export function logError(appInsights: ReactPlugin, text: string, properties?: Record<string, unknown>): void {
    logAppInsightsTrace(appInsights, SeverityLevel.Error, text, properties);
}
