export function safePushAll<T>(baseValues: T[], newValues: T[]): T[] {
    if (baseValues === undefined) {
        baseValues = [];
    }
    if (!newValues?.length) {
        newValues = [];
    }
    baseValues.push(...newValues);
    return baseValues;
}
